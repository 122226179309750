<template>
  <div>
    <div class="activity">
      <div class="activity_head">
        <div class="activity_head_left">
          <div class="activity_head_img">
            <img :src="routeRow.activityCover" alt="" />
          </div>
          <div class="activity_head_context">
            <div>
              <div class="activity_head_serialNumber">{{ routeRow.activityName }}</div>
            </div>
            <div>
              <div class="activity_head_text">
                <i class="el-icon-office-building"></i>
                主办方：{{ routeRow.companyName }}
              </div>
              <div class="activity_head_time">
                <i class="el-icon-time"></i>
                时间：{{ routeRow.startTime }} 至 {{ routeRow.endTime }}
              </div>
            </div>
          </div>
        </div>
        <div class="activity_head_right">
          <div class="activity_head_register">
            <div class="activity_head_register_number">{{ rateQueryData.applyNumber }}</div>
            <div class="activity_head_register_string">总报名人数</div>
          </div>
          <div class="activity_head_conversion">
            <div class="activity_head_register_number">{{ rateQueryData.lookNumber }}</div>
            <div class="activity_head_register_string">查看人数</div>
            <div class="line"></div>
          </div>

          <div class="activity_head_echarts">
            <div class="pie">
              <el-progress
                type="circle"
                :percentage="rateQueryData.rate || 0"
                color="#7C71E1"
                :width="64"
                :hieght="64"
                :stroke-width="8"
              ></el-progress>
            </div>
            <div class="text">分销转化率</div>
          </div>
        </div>
      </div>

      <!-- context -->
      <div class="activity_context">
        <div class="context_inquire">
          <!-- 用户等级 -->
          <div class="Grade">
            <span class="leb">用户等级：</span>
            <span class="span_button" v-for="item in GradeList" :key="item.id">
              <el-button :class="GradeActive == item.id ? 'active' : ''" @click="clickGrade(item)">
                {{ item.value }}
              </el-button>
            </span>
          </div>
          <!-- 链接失效时间 -->
          <div class="time">
            <span class="leb">链接失效时间：</span>
            <el-date-picker
              v-model="timeVal"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始"
              end-placeholder="结束"
              prefix-icon="el-icon-date"
              @change="timeChange"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </div>
        </div>

        <!-- 导出和搜索 -->
        <div class="extract_search">
          <!-- 导出 -->
          <div class="extract" @mouseleave="mouseleaveExport">
            <el-button
              :class="extractClickButton == true ? 'deep_button' : 'pure_button'"
              @click="clickExport"
            >
              <img
                v-if="extractClickButton"
                src="../../../static/images/jr-icon-out-copy.png"
                alt=""
              />
              <img v-else src="../../../static/images/jr-icon-out-copy (1).png" alt="" />

              批量导出
            </el-button>
          </div>
          <!-- 搜索 -->
          <div class="search">
            <el-input
              v-model="inputVal"
              placeholder="请输入用户昵称，手机号查询"
              clearable
            ></el-input>
            <el-button @click="searchClick" class="deep_button">搜索</el-button>
          </div>
        </div>

        <!-- table表格 -->
        <div class="table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            :header-cell-style="{ background: '#F6F6F6', color: '#333333' }"
            :row-key="(row) => row.id"
            @sort-change="changeTableSort"
          >
            <el-table-column type="selection" width="55" align="center"> </el-table-column>
            <el-table-column label="编号" align="center" prop="编号">
              <template slot-scope="scope">{{ scope.row.serialNumber }}</template>
            </el-table-column>

            <el-table-column sortable="custom" label="分销转化率" align="center" prop="分销转化率">
              <template slot-scope="scope">
                <div>
                  <el-progress
                    type="circle"
                    :percentage="scope.row.distributionPercentConversion"
                    color="#7C71E1"
                    :width="43"
                    :hieght="43"
                    :stroke-width="4"
                  ></el-progress>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="头像" align="center" prop="头像">
              <template slot-scope="scope">
                <img
                  style="
                     {
                      width: 48px;
                      height: 48px;
                      border-radius: 4px;
                    }
                  "
                  :src="scope.row.headPortrait"
                  alt=""
                />
              </template>
            </el-table-column>
            <el-table-column label="姓名" align="center" prop="姓名">
              <template slot-scope="scope">{{ scope.row.userName }}</template>
            </el-table-column>

            <el-table-column label="手机号" align="center" prop="手机号">
              <template slot-scope="scope">{{ scope.row.mobile }}</template>
            </el-table-column>

            <el-table-column label="用户等级" align="center" prop="用户等级">
              <template slot-scope="scope">
                <div v-if="scope.row.whetherMember === 1">普通会员</div>
                <div v-if="scope.row.whetherMember === 5">超级会员</div>
              </template>
            </el-table-column>

            <el-table-column label="链接失效时间" align="center" width="200" prop="链接失效时间">
              <template slot-scope="scope"
                >{{ scope.row.startTime }} 至 {{ scope.row.endTime }}</template
              >
            </el-table-column>
            <el-table-column sortable="custom" label="查看人数" align="center" prop="查看人数">
              <template slot-scope="scope">
                <div>{{ scope.row.lookNumber }}</div>
              </template>
            </el-table-column>
            <el-table-column sortable="custom" label="报名人数" align="center" prop="报名人数">
              <template slot-scope="scope">
                <div>{{ scope.row.applyNumber }}</div>
              </template>
            </el-table-column>
            <!-- <el-table-column sortable="custom" label="签到人数" align="center" prop="签到人数">
              <template slot-scope="scope">
                <div>{{ scope.row.signInNumber }}</div>
              </template>
            </el-table-column> -->

            <el-table-column label="操作" align="center" width="180" prop="操作">
              <template slot-scope="scope">
                <div class="color" @click="ToDistributionList(scope.row)">分销列表</div>
              </template>
            </el-table-column>
          </el-table>

          <!-- 分页 -->
          <div class="Pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 30, 50]"
              :page-size="100"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { selectDistribution, exportExcelAll, selectRate } from '../../api/distribution'

export default {
  components: {},
  data() {
    //这里存放数据
    return {
      // 分销管理页面传递过来的数据
      routeRow: {},
      GradeActive: 999, // 用户等级
      Communication: 999, // 沟通状态
      // 用户等级
      GradeList: [
        {
          value: '全部',
          id: 999
        },
        {
          value: '普通会员',
          id: 1
        },
        {
          value: '超级会员',
          id: 5
        }
      ],
      CommunicationStatus: [
        {
          value: '全部',
          id: 999
        },
        {
          value: '未联系',
          id: 0
        },
        {
          value: '已联系',
          id: 1
        }
      ],
      // 元素的类名控制
      extractClickButton: false,
      // 请输入用户昵称，手机号查询的val
      inputVal: '',
      // 活动分销请求发送的数据
      querySearch: {
        current: 1,
        distributionType: 0,
        endTime: '',
        nameOrMobile: '',
        size: 10,
        startTime: '',
        typeId: '',
        whetherMember: null,
        order: '',
        orderType: null
      },
      // 表格数据
      tableData: [],
      //总数据条数
      total: 0,
      // 当前页数
      currentPage: 1,
      // 选中的表格id数组
      tableIdList: [],
      timeVal: [], // 失效时间
      exportExcelAllName: '', // 导出名字
      // 分销转化率调用接口的数据
      selectRateQuery: { activityId: '', type: 0 },
      // 分销转化率对象
      rateQueryData: {}
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    clickGrade(item) {
      if (item.id == 999) {
        this.GradeActive = item.id
        this.querySearch.whetherMember = null
        this.search()
      } else {
        this.GradeActive = item.id
        this.querySearch.whetherMember = this.GradeActive
        this.search()
      }
      // console.log(this.GradeActive)
    },
    // 沟通状态
    contactClick(item) {
      if (item.id == 999) {
        this.Communication = item.id
        this.querySearch.isSignIn = null
        this.search()
      } else {
        this.Communication = item.id
        this.querySearch.isSignIn = this.Communication
        this.search()
      }
    },
    // 鼠标移除批量导出元素
    mouseleaveExport() {
      this.extractClickButton = false
    },
    // 批量导出
    async clickExport() {
      // 判断有没有勾选数据
      if (!this.tableIdList.length > 0) {
        return this.$message.warning('敬请期待!')
      }
      // 改变按钮颜色
      this.extractClickButton = true
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const { data: res } = await exportExcelAll({
        id: this.routeRow.id,
        idList: this.tableIdList
      })
      // console.log(this.routeRow.userName)
      if (res) {
        loading.close()
        let blob = new Blob([res])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        a.href = href // 指定下载'链接
        a.download = this.exportExcelAllName + '分销人员列表' + '.xlsx' //指定下载文件名
        // a.download = "test.png";
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
      } else {
        this.$message.error('导出失败!')
        loading.close()
      }
    },
    // 点击搜索
    searchClick() {
      this.querySearch.nameOrMobile = this.inputVal
      this.search()
    },
    // table表格数据
    async search() {
      const { data: res } = await selectDistribution(this.querySearch)
      if (res.resultCode === 200) {
        this.tableData = res.data.list
        this.total = res.data.total
      }
      // console.log(res)
    },
    //表格按钮点击选中事件
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.tableIdList = []
      this.multipleSelection.forEach((item) => {
        this.tableIdList.push(item.id)
      })
      // console.log(val)
    },
    // 操作->分销列表->要跳转
    ToDistributionList(row) {
      // console.log(row)
      // sessionStorage.setItem('routeRow2', JSON.stringify(row))
      this.$router.push({
        path: '/activity/distributionList',
        query: { query: JSON.stringify(row) }
      })
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.querySearch.size = val
      this.search()
    },
    // 分页
    handleCurrentChange(val) {
      this.querySearch.current = val
      this.search()
      // console.log(`当前页: ${val}`)
    },

    // 表格排序
    changeTableSort(column) {
      // console.log(column.prop, 'prop')
      // console.log(column.order, 'order')
      if(column.order==null){
        this.querySearch.order=''
        this.querySearch.orderType=null
        this.search()
      }
      if (column.prop == '分销转化率' && column.order == 'ascending') {
        this.querySearch.order = 'asc'
        this.querySearch.orderType = 0
        this.search()
      } else if (column.prop == '分销转化率' && column.order == 'descending') {
        this.querySearch.order = 'desc'
        this.querySearch.orderType = 0
        this.search()
      }

      if (column.prop == '查看人数' && column.order == 'ascending') {
        this.querySearch.order = 'asc'
        this.querySearch.orderType = 1
        this.search()
      } else if (column.prop == '查看人数' && column.order == 'descending') {
        this.querySearch.order = 'desc'
        this.querySearch.orderType = 1
        this.search()
      }

      if (column.prop == '报名人数' && column.order == 'ascending') {
        this.querySearch.order = 'asc'
        this.querySearch.orderType = 4
        this.search()
      } else if (column.prop == '报名人数' && column.order == 'descending') {
        this.querySearch.order = 'desc'
        this.querySearch.orderType = 4
        this.search()
      }
    },
    // 失效时间
    timeChange() {
      if (this.timeVal !== null) {
        this.querySearch.startTime = this.timeVal[0]
        this.querySearch.endTime = this.timeVal[1]
        this.search()
      } else if (this.timeVal == null) {
        this.timeVal = []
        this.querySearch.startTime = ''
        this.querySearch.endTime = ''
        this.search()
      }
    },
    // 查询三种类型的分销转换率（0：活动；1：需求；2：报备）
    async getSelectRate() {
      const { data: res } = await selectRate(this.selectRateQuery)
      // console.log(res)
      if (res.resultCode === 200) {
        this.rateQueryData = res.data
      }
    }
  },

  created() {
    // 分销主页面传递过来的值

    // this.routeRow = JSON.parse(sessionStorage.getItem('routeRow1'))
    this.routeRow = JSON.parse(this.$route.query.query)
    // console.log(this.routeRow)
    this.selectRateQuery.activityId = this.routeRow.id
    this.querySearch.typeId = this.routeRow.id
    this.exportExcelAllName = this.routeRow.activityName
    // 调用table数据
    this.search()
    // 分销转化率
    this.getSelectRate()
  },

  mounted() {}
}
</script>
<style scoped lang="less">
.activity {
  padding: 16px 15px 0 23px;
  .activity_head {
    width: 100%;
    height: 121px;
    background-color: #fff;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .activity_head_left {
      box-sizing: border-box;
      width: 50%;
      height: 100%;
      padding: 18px 0 16px 18px;
      display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      .activity_head_img {
        width: 166px;
        height: 90px;
        margin-right: 8px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
      .activity_head_context {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .activity_head_serialNumber {
          font-size: 20px;
          color: #333;
        }
        .activity_head_text {
          font-size: 14px;
          color: #333;
          margin-bottom: 5px;
        }
        .activity_head_time {
          font-size: 14px;
          color: #333;
        }
      }
    }
    .activity_head_right {
      width: 50%;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: row-reverse;
      .activity_head_register {
        margin: 0 80px 0 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        .activity_head_register_number {
          font-size: 24px;
          color: #7c71e1;
        }
        .activity_head_register_string {
          font-size: 12px;
          color: #333;
        }
      }

      .activity_head_conversion {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin-left: 110px;
        .activity_head_register_number {
          font-size: 24px;
          color: #7c71e1;
        }
        .activity_head_register_string {
          font-size: 12px;
          color: #333;
        }
        .line {
          position: absolute;
          left: -60px;
          top: 37px;
          width: 60px;
          height: 38px;
          border-left: 2px solid #ededed;
        }
      }

      .activity_head_echarts {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        .pie {
          width: 64px;
          height: 64px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .text {
          font-size: 12px;
          color: #333;
        }
      }
    }
  }

  .activity_context {
    background-color: #fff;
    box-sizing: border-box;
    width: 100%;
    border-radius: 12px;
    padding: 0 16px 0 20px;
    .context_inquire {
      width: 100%;
      height: 64px;
      border-bottom: 1px dashed #ededed;
      display: flex;
      align-items: center;

      .Grade {
        margin: 0 60px 0 0;
        .leb {
          font-size: 16px;
        }
        .span_button {
          margin-right: 15px;

          .el-button {
            font-size: 14px;
          }
        }
      }
    }

    .extract_search {
      box-sizing: border-box;
      width: 100%;
      height: 65px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 50px;

      .extract {
        img {
          width: 16px;
          height: 16px;
          margin-right: 3px;
        }

        /deep/ .el-button span {
          display: flex;
          align-items: center;
        }
      }
      .search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /deep/.el-input__inner {
          width: 382px;
          height: 32px;
        }
        /deep/.el-input__suffix {
          display: flex;
          align-items: center;
        }
        /deep/.el-button {
          margin-left: 16px;
        }
      }
    }

    .table {
      width: 100%;
      // height: 100%;
      padding-bottom: 80px;
      position: relative;

      .to_distribution_list {
        display: flex;
        justify-content: space-around;
        color: #7c71e1;

        .see {
          cursor: pointer;
        }
      }
      .Pagination {
        width: 100%;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        margin-bottom: 30px;

        /deep/.active {
          width: 28px;
          height: 28px;
          border-radius: 2px;
          background-color: #7c71e1;
          color: #fff;
          font-size: 12px;
          min-width: 28px;
          font-weight: 400;
        }
      }

      .not {
        color: #ff7043;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .back_not {
          width: 8px;
          height: 8px;
          background-color: #ff7043;
          border-radius: 50%;
        }
      }

      .already {
        color: #7c71e1;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .back_already {
          width: 8px;
          height: 8px;
          background-color: #7c71e1;
          border-radius: 50%;
        }
      }
    }
  }
  .active {
    color: #fff !important;
    background-color: #8598ff !important;
  }
  .pure_button {
    border-radius: 4px;
    border: 1px solid #7c71e1;
    color: #7c71e1;
    font-size: 14px;
  }
  .pure_button:hover {
    border-radius: 4px;
    border: 1px solid #7c71e1;
    color: #7c71e1;
    background-color: rgba(124, 113, 225, 0.2);
    font-size: 14px;
  }

  .deep_button {
    border-radius: 4px;
    color: #fff;
    background-color: #7c71e1;
    font-size: 14px;
  }

  .color {
    color: #7c71e1;
    font-size: 12px;
    cursor: pointer;
  }
}
</style>